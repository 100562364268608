import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import CampSlider from '../components/pages/CampSlider';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const Campschoolsout = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="smallestwdt memberships display_none">
                <div
                    className=""
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
            </section>
            <div className="smallestwdt campschoolsout">

                <div className="margin-padding_div"></div>
                <div className="wrapper-hero_top">
                    <div><div
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_hero_products_content,
                        }}
                    /></div>
                    <div>
                     {/*}   <div
                            id="myCarousel3"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <CampSlider
                                galleryImages={post.acf.camp_gallery}
                            />
                        </div>  */}
                        <div class="elfsight-app-49f05c20-01eb-4f6f-9568-4892796eeaff" data-elfsight-app-lazy></div>
                    </div>
                   
                </div>
                <img className="linemar marg-liner mobs" src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2020/06/linefull.jpg" alt="linefull" />
                <section className="button_ex_sec"><div className="testinng-anchors"><a href="https://app.jotform.com/240388001752148" target="_blank">Looking to book select dates -- Click Here</a></div></section>
                <div className="werockcareheal campout-heal">
                <div
                    className=""
                    dangerouslySetInnerHTML={{
                        __html: post.acf.healcode_camp,
                    }}
                />
                </div>
                {/* <img className="linemar marg-liner" src="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2020/06/linefull.jpg" alt="linefull" /> */}
                <div className="main-flex_camp bg-secondary">
                    <h2 className="mainh2-camp">Camp Activities and Classes</h2>
                    <div className="column-flex_camp">

                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_1,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_1,
                                }}
                            /></div>
                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_2,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_2,
                                }}
                            /></div>

                    </div>
                </div>
              
                <div className="main-flex_camp bg-primary">
                    <div className="column-flex_camp">

                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_3,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_3,
                                }}
                            /></div>
                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_4,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_4,
                                }}
                            /></div>

                    </div>
                </div>
                <div className="main-flex_camp bg-secondary">
                    <div className="column-flex_camp">

                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_5,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_5,
                                }}
                            /></div>
                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_6,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_6,
                                }}
                            /></div>

                    </div>
                </div>
                <div className="main-flex_camp bg-primary">
                    <div className="column-flex_camp">

                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_7,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_7,
                                }}
                            /></div>
                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_8,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_8,
                                }}
                            /></div>

                    </div>
                </div>
                <div className="main-flex_camp bg-secondary">
                    <div className="column-flex_camp">

                        <div> <h3
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: post.acf.camp_s_9,
                            }}
                        /> <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_desc_9,
                                }}
                            /></div>
                        <div></div>

                    </div>
                </div>
                <section className="whywelist page-section bg-primary text-white" id="openpbasic">
                    <div class="container flexwrap whywebluelist">
                        <div class="section_memberships whylistrgt flexbox toup"><h2 className="yellowtxt">ADDITIONAL INFORMATION</h2>
                            <ul class="startxt normalfontlist">
                                <li>FOR CHILDREN AGES 5 TO 12 YEARS. CHILDREN MUST BE POTTY TRAINED.</li>
                                <li>UNIQUE AND DIFFERNTIATED THEMES EACH WEEK FOR MULTI-WEEK SUMMER AND WINTER CAMPS.</li>
                                <li>HOLIDAY THEME BASED ACTIVITIES FOR HOLIDAY CAMPS WITH FOCUS ON SOCIAL SKILLS, ART AND CRAFT, BOOK READING AND CREATIVE SKILLS.</li>
                                <li>ALL OUR SCHOOL'S OUT CAMPS ARE A OPTIMIZED MIX OF STEAM ACTIVITIES.
</li>
                                <li>SNACKS & WATER ARE PROVIDED; LUNCH MUST BE PROVIDED BY PARENTS.
</li>
                                <li>GIVE US A CALL AT THE GYM IF YOU FEEL YOUR CHILD NEEDS ONE-TO-ONE ASSISTANCE.
</li>
                                <li>A DIGITALLY SIGNED ENROLLMENT FORM IS REQUIRED FOR SIGNUP.
</li>
                                <li>WE ROCK THE SPECTRUM IS NOT A LICENSED DAYCARE FACILITY.</li>
                            </ul>
                        </div>
                    </div>
                    <script src="https://static.elfsight.com/platform/platform.js" async></script>
                    <div
                        className="elfsight-app-ddf8e915-4ecc-4600-a8c0-4ac332c1bc77"
                        data-elfsight-app-lazy
                        style={{ marginTop: "80px" }}
                    ></div>
                </section>

                {/* <section className="page-section bg-secondary text-white centersec para_para">
                    <div className="container smallestwdt"><h2 class="yellowtxt">Open Play Memberships</h2>
                        <p className="biggertxt"><p>Learn More about Open Play Drop-In and Passes <a href="/open-play/">Here</a>.</p>
                        </p></div>
                </section> */}

            </div>


            <Footer />
        </>
    );
};
export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            acf {
               healcode_camp
                open_play_hero_products_content
               camp_s_1
               camp_desc_1
               camp_s_2
               camp_desc_2
               camp_s_3
               camp_desc_3
               camp_s_4
               camp_desc_4
               camp_s_5
               camp_desc_5
               camp_s_6
               camp_desc_6
               camp_s_7
               camp_desc_7
               camp_s_8
               camp_desc_8
               camp_s_9
               camp_desc_9
               camp_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;

export default Campschoolsout;
